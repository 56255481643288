<template>
    <div class="report_box" v-if="isLogin">
        <h3>提现记录</h3>
        <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="finishedtext"
            @load="onLoad"
        >
            <template v-if="list.length > 0">
                <div class="list_box" v-for="(item,index) in list" :key="index" @click="goDetail(item)">
                    <div class="title">
                        <p class="name">{{item.project_title}}</p>
                        <p class="price">{{item.amount/100}}</p>
                    </div>
                    <div class="dec">
                        <p class="date">{{item.create_time | format}}</p>
                        <p class="state" :class="{red: item.state == 10 || item.state == 50, gary: item.state == 40}">{{item.state | getStatus}}</p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div v-if="isOk">
                    <img style="width: 200px;margin-top: 100px;" src="./image/black.png" alt="">
                </div>
            </template>
        </van-list>
    </div>
</template>
<script>
import { List } from 'vant';
import { authChecker, shareCtrl } from '@/common/js/mmcModule';
import { getWithdrawals } from './api/index';
export default {
    data() {
        return {
            list: [],
            loading: false,
            finished: false,
            finishedtext: '',
            page: 1,
            totalPage: 1,
            isLogin: false,
            isOk: false
        };
    },
    created() {
        shareCtrl.init();
    },
    mounted() {
        const that = this
        authChecker.check({ notneedphone: 1 },() => {
            that.isLogin = true
        })
    },
    filters: {
        format(time) {
            const add0 = m => m<10?'0'+m:m;
            time = new Date(time * 1000);
            let y = time.getFullYear();
            let m = time.getMonth()+1;
            let d = time.getDate();
            let h = time.getHours();
            let mm = time.getMinutes();
            let s = time.getSeconds();
            return y+'-'+add0(m)+'-'+add0(d)+' '+add0(h)+':'+add0(mm)+':'+add0(s);

        },
        getStatus(e) {
            const state = {
                1: '待审核',
                10: '未通过审核，请重新提交',
                20: '审核通过，待打款',
                30: '审核通过，打款处理中',
                40: '提现成功',
                50: '打款失败',
            }
            return state[e];
        }
    },
    components: {
        vanList: List
    },
    methods: {
        goDetail(e) {
            const { withdrawals_id, project_uuid } =e;
            this.$router.push({
                path: '/fund/withdraw/timeline',
                query: {
                    withdrawals_id,
                    projuuid: project_uuid
                }
            })
        },
        onLoad() {
            getWithdrawals(this.page).then(res => {
                this.isOk = true
                if(res.code == 0) {
                    this.list = this.list.concat(res.data && res.data.list)

                    this.loading = false;
                    this.finishedtext = this.page > 1 ? '没有更多了' : '';
                    this.totalPage = Math.ceil((res.data && res.data.total/10) || 1);
                    if (this.totalPage <= this.page) {
                        this.finished = true;
                    }
                    this.page = this.page + 1
                }else{
                    this.finished = true;
                }

            })
        },
    },
}
</script>
<style scoped lang="less">
.report_box {
    padding: 35px 20px;
    width: 100%;
    overflow-x: hidden;
    h3 {
        font-size: 34px;
        text-align: left;
        margin-bottom: 10px;
    }
    .list_box {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
                font-size: 17px;
                color: #333;
                max-width: 70%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .price {
                font-size: 20px;
                font-weight: bold;
                color: #333;
            }
        }
        .dec {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            .date {
                font-size: 13px;
                color: #999;
            }
            .state {
                font-size: 13px;
                color: #009BFF;
                &.red{
                    color: #FF434B;
                }
                &.gary {
                    color: #999;
                }
            }
        }
    }
}
</style>
